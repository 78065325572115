import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Application,
  ContextMenuAction,
  Country,
  DataComponentFacade,
  DataSourceService,
  GroupUser,
  Ishtar365CommunicationService,
  Language,
  LoaderService,
  MicrosoftAuthenticationService,
  PropertyRight,
  PropertyType,
  TranslationService,
} from 'processdelight-angular-components';
import { BehaviorSubject, Subject, combineLatest, forkJoin, timer } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { UserLicenseInfo } from '../models/userlicenseinfo';
import { IshtarCRMService } from './ishtarcrm.service';
import { AppComponent } from '../app.component';
export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const varcountries$ = new BehaviorSubject<Country[] | undefined>(
  undefined
);

export const varusers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const vargroups$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const graphUsers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const varlanguages$ = new BehaviorSubject<Language[] | undefined>(
  undefined
);
export const vartranslations$ = new BehaviorSubject<any>({});
export const propertyTypes$ = new BehaviorSubject<PropertyType[]>([]);
export const propertyRights$ = new BehaviorSubject<PropertyRight[]>([]);
export const currentApplication$ = new BehaviorSubject<Application | undefined>(
  undefined
);

export const navActions$ = new BehaviorSubject<ContextMenuAction<unknown>[]>(
  []
);

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  destroy$ = new Subject<void>();

  constructor(
    private ishtarCRMService: IshtarCRMService,
    private loaderService: LoaderService,
    private dataPropertyFacade: DataComponentFacade,
    private router: Router,
    private ishtarCommunicationService: Ishtar365CommunicationService,
    private route: ActivatedRoute,
    private msal: MicrosoftAuthenticationService,
    private translationService: TranslationService,
    private dataSourceService: DataSourceService
  ) {}

  getLicense() {
    return this.loaderService.startLoading$(
      'Retrieving license information',
      () =>
        this.ishtarCRMService.getLicense(this.msal.tenantId).pipe(
          tap((data) => varlicense$.next(data)),
          catchError((error) => {
            this.router.navigate(['/401']);
            throw error;
          })
        )
    );
  }

  getCountries() {
    return this.ishtarCRMService
      .getCountries()
      .pipe(tap((data) => varcountries$.next(data)));
  }

  getUsers() {
    return this.ishtarCRMService
      .getUsers()
      .pipe(tap((data) => varusers$.next(data)));
  }

  getGraphUsers() {
    return this.ishtarCRMService.getGraphUsers().pipe(
      map((data) => data.map((u) => new GroupUser({ user: u }))),
      tap((data) => graphUsers$.next(data))
    );
  }

  getPropertyTypes() {
    return this.ishtarCRMService.getPropertyTypes().pipe(
      tap((data) => {
        const types = data;
        const textType = types.find((t) => t.type === 'Text field');
        if (textType) {
          textType.icon = 'text_fields';
        }
        propertyTypes$.next(data);
      })
    );
  }

  getForms() {
    return this.dataPropertyFacade.getForms();
  }

  getDataProperties() {
    return this.dataPropertyFacade.getDataProperties$();
  }

  getPropertyRights() {
    return this.ishtarCRMService.getPropertyRights().pipe(
      tap((data) => {
        propertyRights$.next(data);
      })
    );
  }

  getCurrentApplication() {
    return this.ishtarCRMService
      .getCurrentApplication()
      .pipe(tap((data) => currentApplication$.next(data)));
  }

  getGroups() {
    return this.ishtarCRMService
      .getGroups()
      .pipe(tap((data) => vargroups$.next(data)));
  }

  getLanguages() {
    return this.ishtarCRMService
      .getLanguages()
      .pipe(tap((data) => varlanguages$.next(data)));
  }

  getInterestGroups() {
    return this.ishtarCRMService
      .getInterestGroups()
      .pipe(
        tap((data) => this.dataSourceService.updateData('interestGroups', data))
      );
  }

  getTranslations() {
    return this.ishtarCRMService.getTranslations().pipe(
      tap((data) => vartranslations$.next(data)),
      tap((data) => this.translationService.update(data))
    );
  }

  getFormTemplates() {
    return this.dataPropertyFacade.getFormTemplates$();
  }

  boot() {
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.msal.signedIn.value),
        switchMap(() => this.ishtarCRMService.sessionKeepAlive())
      )
      .subscribe();
    return this.getLicense().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (
          !license?.licenses.some(
            (l) => l.productName == AppComponent.domainName
          )
        ) {
          this.router.navigate(['/401']);
          throw new Error('No license for Ishtar.CRM');
        }

        return this.loaderService.startLoading$('Starting application...', () =>
          forkJoin([
            this.getTranslations().pipe(
              filter((t) => !!Object.keys(t).length),
              first()
            ),
            this.getCountries(),
            this.getUsers(),
            this.getGraphUsers(),
            this.getPropertyTypes(),
            this.getForms(),
            this.getDataProperties(),
            this.getPropertyRights(),
            this.getCurrentApplication(),
            this.getGroups(),
            this.getLanguages(),
            this.getFormTemplates(),
            this.getInterestGroups(),
          ]).pipe(
            tap(() => {
              this.dataSourceService.updateDataWithObservable(
                'peopleAndGroups',
                combineLatest([vargroups$, graphUsers$]).pipe(
                  map(([groups, users]) => (groups ?? []).concat(users ?? []))
                )
              );

              this.dataSourceService.updateDataWithObservable(
                'people',
                graphUsers$
              );

              this.dataSourceService.updateDataWithObservable(
                'groups',
                vargroups$
              );

              this.dataSourceService.updateDataWithObservable(
                'relationFieldTables',
                this.ishtarCRMService.getRelationFieldTables()
              );

              this.dataSourceService.updateDataWithObservable(
                'countries',
                varcountries$
              );

              try {
                this.ishtarCommunicationService.init();
                this.ishtarCommunicationService.registerRedirectAction(
                  'OpenClient',
                  (id?: string, data?: unknown) => {
                    const segments = this.route.snapshot.url.map((s) => s.path);
                    if (segments[0] !== 'clients')
                      this.router.navigate(['clients'], {
                        queryParams: { clientId: id },
                      });
                  }
                );
                this.ishtarCommunicationService.registerRedirectAction(
                  'CRMEntity',
                  (id?: string, data?: unknown) => {
                    this.router.navigate(['clients'], {
                      queryParams: { recordId: id },
                    });
                  }
                );
                this.ishtarCommunicationService.registerRedirectAction(
                  'ShortCut',
                  (id?: string, data?: any) => {
                    if (data?.configuration) {
                      this.router.navigate(['clients'], {
                        state: { data: data?.configuration },
                      });
                    }
                  }
                );
              } catch (err) {
                // Not in Ishtar365
              }
            })
          )
        );
      })
    );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
