export class RecordDetail {
  id?: string | null
  label?: string | null;
  dataType?: string | null;
  value: any;

  get valueAdjustedByType() {
    try {
      if (this.dataType === 'Decimal' && this.value) {
        return this.value;
      }
      const object = JSON.parse(this.value);
      const isPhonenumber =
        object?.selectedCountry || object?.selectedPhoneNumber;
      const isMultiSelect = object?.selectedIds && object?.selectedValues;

      if (isPhonenumber) {
        const formattedPhoneNumber = `+${object.selectedCountry?.TelephoneCode}${object.selectedPhoneNumber}`;
        return formattedPhoneNumber;
      } else if (isMultiSelect) {
        return object.selectedValues.join(', ');
      }
    } catch (error) {
      return this.value;
    }
  }

  constructor(obj: Partial<RecordDetail>) {
    Object.assign(this, obj);
  }
}
