<div
  class="h-100 w-100 min-height-0"
  *ngIf="{
    orgLogo: orgLogo$ | async,
    userName: userName$ | async,
    userPhoto: userPhoto$ | async,
    navColor: navColor$ | async,
    navContrast: navContrast$ | async
  } as data"
>
  <processdelight-top-layout
    [appLogo]="appLogo"
    [appName]="appName"
    [navColor]="data.navColor || '#124464'"
    [navContrast]="data.navContrast || '#fff'"
    [iconActions]="iconActions"
    [sideBarActions]="sideBarActions"
    [actionBarTitleHidden]="true"
    [buttonActions]="(navActions | async) ?? []"
  >
    <router-outlet></router-outlet>
  </processdelight-top-layout>
</div>
